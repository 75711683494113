import React from 'react'
import { Head } from 'src/components/layout/head'
import { DefaultLayout } from 'src/components/layout/default'
import Contact from 'src/components/pages/Contact'

const ContactPage: React.FCX = () => {
  return (
    <div className="contact-page">
      <Head title="お問い合わせ | 株式会社スムーズ" />
      <DefaultLayout>
        <Contact />
      </DefaultLayout>
    </div>
  )
}

export default ContactPage
