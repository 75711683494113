import React from 'react'
import Title from 'src/components/shared/Title'
import Form from 'src/components/shared/Form'
import { Input } from 'src/components/shared/Input'
import Wrapper from 'src/components/shared/Wrapper'
import { css } from 'styled-components'
import { InputTitle } from 'src/components/shared/Label'
import { Textarea } from 'src/components/shared/Textarea'
import { MainButton } from 'src/components/shared/Button'
import { useContactForm } from './useContactForm'

const Contact: React.FCX = (props) => {
  const {
    handleContactFormChange,
    handleContactFormSubmit,
    validationOk
  } = useContactForm()

  return (
    <div className={`${props.className} page-wrapper`}>
      <Wrapper
        css={css`
          max-width: 680px;
        `}
      >
        <Title type={['color_main']} text="CONTACT" sub="お問い合わせ" />
        <Form onChange={handleContactFormChange}>
          <div className="form-section">
            <InputTitle htmlFor="name" required>
              お名前
            </InputTitle>
            <Input
              name="name"
              id="name"
              type="text"
              placeholder="スムーズ 太郎"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="email" required>
              メールアドレス
            </InputTitle>
            <Input
              name="email"
              id="email"
              type="email"
              placeholder="info@smooth.jp"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="company" required>
              会社名・所属
            </InputTitle>
            <Input
              name="company"
              id="company"
              type="text"
              placeholder="株式会社スムーズ"
            />
          </div>
          <div className="form-section">
            <InputTitle htmlFor="content" required>
              お問い合わせ内容
            </InputTitle>
            <Textarea
              id="content"
              name="content"
              placeholder="株式会社スムーズ"
              rows={6}
            />
          </div>
          <MainButton
            onClick={handleContactFormSubmit}
            type={['normal']}
            disabled={!validationOk}
          >
            送信する
          </MainButton>
        </Form>
      </Wrapper>
    </div>
  )
}

export default Contact
