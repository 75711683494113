/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState, useMemo } from 'react'
import axios from 'axios'
import { SMOOTH_API_URL } from '../../../../config'

interface IContactState {
  type: 'CORP'
  name: string
  email: string
  company: string
  content: string
}

export const useContactForm = () => {
  const [contactFormValue, setContactFormValue] = useState<IContactState>({
    type: 'CORP',
    name: null,
    email: null,
    company: null,
    content: null
  })
  const handleContactFormChange = (e: React.FormEvent) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    setContactFormValue((v) => ({
      ...v,
      [target.name]: target.value
    }))
  }

  const handleContactFormSubmit = async () => {
    await axios
      .post(`${SMOOTH_API_URL}/public_api/inquiry_emails`, contactFormValue)
      .catch((err) => {
        // eslint-disable-next-line no-alert
        alert(err)
        throw new Error(err)
      })
    // eslint-disable-next-line no-alert
    alert('送信に成功しました')
  }

  const validationOk = useMemo(() => {
    return !!(
      contactFormValue.name &&
      contactFormValue.company &&
      contactFormValue.email &&
      contactFormValue.content
    )
  }, [
    contactFormValue.company,
    contactFormValue.content,
    contactFormValue.email,
    contactFormValue.name
  ])

  return {
    handleContactFormChange,
    contactFormValue,
    handleContactFormSubmit,
    validationOk
  }
}
